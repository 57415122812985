import { addDays, differenceInMinutes, format, subDays } from 'date-fns'
import { DAY_MS } from './constants'

export const getUTCDateTime = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - userTimezoneOffset)
}

// This function can be replaced by formatDateWithTimeZoneStr
export const getLocalDateString = (date: string | Date) =>
  new Date(date).toLocaleDateString('en-gb')

export const getDateString = (date: string | Date) => {
  const newDate = new Date('2013-08-03T02:00:00Z')
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const dt = newDate.getDate()

  return `${dt < 10 ? '0' : ''}${dt}${month < 10 ? '0' : ''}${month}${year}`
}

export const getDateDiff = (from: Date, to: Date, span: 'day' | 'week'): number => {
  const msDiff =
    new Date(to.toDateString()).getTime() -
    new Date(`${from.toDateString()} 23:59:59.999`).getTime()
  switch (span) {
    case 'day':
      return Math.floor(msDiff / DAY_MS)
    case 'week':
      return Math.floor(msDiff / DAY_MS / 5)
    default:
      return msDiff
  }
}

export const getDateTime = (date: Date, includeSec = false, dateTimeSeperator = ' ') => {
  const dateSplit = format(date, 'yyyy-MM-dd hh-mm-ss').split(/[\sT:.-]+/)
  const returnDate = `${dateSplit[2]}/${dateSplit[1].padStart(2, '0')}/${dateSplit[0].padStart(
    2,
    '0'
  )}`
  let returnTime = `${dateSplit[3].padStart(2, '0')}:${dateSplit[4].padStart(2, '0')}`
  if (includeSec) {
    returnTime = `${returnTime}:${dateSplit[5].padStart(2, '0')}`
  }
  return `${returnDate}${dateTimeSeperator}${returnTime}`
}

export const getDateTime24HrFormat = (date: Date, includeSec = false, dateTimeSeperator = ' ') => {
  const dateSplit = format(date, 'yyyy-MM-dd HH-mm-ss').split(/[\sT:.-]+/)
  const returnDate = `${dateSplit[2]}/${dateSplit[1].padStart(2, '0')}/${dateSplit[0].padStart(
    2,
    '0'
  )}`
  let returnTime = `${dateSplit[3].padStart(2, '0')}:${dateSplit[4].padStart(2, '0')}`
  if (includeSec) {
    returnTime = `${returnTime}:${dateSplit[5].padStart(2, '0')}`
  }
  return `${returnDate}${dateTimeSeperator}${returnTime}`
}

export const getDateArrayFromRange = (
  startDate: Date,
  endDate: Date,
  formatString: string
): string[] => {
  const retVal = [format(startDate, formatString)]
  let current = startDate

  while (current < endDate) {
    current = addDays(current, 1)
    retVal.push(format(current, formatString))
  }

  return retVal
}

export const absoluteDate = (d: Date): string => {
  if (!d.setHours) {
    return format(new Date(new Date(d).setHours(1)), 'yyyy-MM-dd')
  }
  return format(new Date(d.setHours(1)), 'yyyy-MM-dd')
}

const lastSunday = (month: number, year: number) => {
  const d = new Date()
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month + 1, 0))
  const day = lastDayOfMonth.getDay()
  return new Date(
    Date.UTC(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate() - day
    )
  )
}

const isBST = (date: Date) => {
  const d = date || new Date()
  const starts = lastSunday(2, d.getFullYear())
  starts.setHours(1)
  const ends = lastSunday(9, d.getFullYear())
  starts.setHours(1)
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime()
}

export const formatDateWithTimeZone = (dateStr: Date | string) => {
  let formattedDate
  if (Object.prototype.toString.call(dateStr) === '[object Date]') {
    formattedDate = dateStr as Date
  } else {
    formattedDate = new Date(dateStr)
  }

  let userTimezoneOffset = formattedDate.getTimezoneOffset() * 60000
  if (isBST(formattedDate)) {
    userTimezoneOffset += 3600000
  }
  const finalDate =
    userTimezoneOffset >= 0
      ? new Date(formattedDate.getTime() + userTimezoneOffset)
      : new Date(formattedDate.getTime() - userTimezoneOffset)
  return finalDate
}

export const formatDateWithTimeZoneStr = (dateStr: Date | string, formatStr?: string) => {
  if (formatStr) {
    return format(formatDateWithTimeZone(dateStr), formatStr)
  }

  return format(formatDateWithTimeZone(dateStr), 'yyyy-MM-dd')
}

export const formatDateTimeWithTimeZoneBST = (dateStr: Date | string) => {
  let formattedDate
  if (Object.prototype.toString.call(dateStr) === '[object Date]') {
    formattedDate = dateStr as Date
  } else {
    formattedDate = new Date(dateStr)
  }

  let userTimezoneOffset = formattedDate.getTimezoneOffset() * 60000

  if (isBST(formattedDate)) {
    userTimezoneOffset += 3600000
  }

  return new Date(formattedDate.getTime() + userTimezoneOffset)
}

export const formatDatTimeWithTimeZone = (dateStr: Date | string) => {
  let formattedDate
  if (Object.prototype.toString.call(dateStr) === '[object Date]') {
    formattedDate = dateStr as Date
  } else {
    formattedDate = new Date(dateStr)
  }

  const userTimezoneOffset = formattedDate.getTimezoneOffset() * 60000

  return new Date(formattedDate.getTime() + userTimezoneOffset)
}

export const formatDatTimeWithTimeZoneStr = (dateStr: Date | string) =>
  format(formatDatTimeWithTimeZone(dateStr), 'yyyy-MM-dd HH:mm')

export const groupDatesIntoRanges = (dates: Date[]): [[Date, Date]?] => {
  if (!dates.length) {
    return []
  }
  const localDates: [[Date, Date]?] = []
  let firstDate = new Date()
  let lastDate = new Date()
  let cnt = -1
  dates.forEach((date, idx) => {
    const currentIterationDate = formatDateWithTimeZone(date)
    cnt += 1
    if (idx === 0) {
      firstDate = currentIterationDate
      lastDate = currentIterationDate
      return
    }
    if (
      format(addDays(firstDate, cnt), 'yyyy-MM-dd hh-mm-ss') <
      format(currentIterationDate, 'yyyy-MM-dd hh-mm-ss')
    ) {
      // The current interation date has advanced beyond the firstDate+increment,
      // indicating a contiguous break. e.g. 1st/2nd/3rd >>> 7th
      localDates.push([firstDate, lastDate])
      firstDate = currentIterationDate
      lastDate = currentIterationDate
      cnt = 0
      return
    }
    lastDate = currentIterationDate
  })
  localDates.push([firstDate, lastDate])
  return localDates
}

export const nextWorkingDay = (day: Date, areWeekendsWorked: boolean): Date => {
  const newDate = new Date(day)
  newDate.setHours(1)
  let increment = 1
  if (!areWeekendsWorked) {
    increment = newDate.getDay() === 5 ? 3 : increment
    increment = newDate.getDay() === 6 ? 2 : increment
  }
  return addDays(newDate, increment)
}

export const getOrdinal = (day: number): string => {
  if (day > 3 && day < 21) return `${day}th`
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

export const getMonday = (date: Date, previous: boolean, skipCurrent = false) => {
  let localDate = new Date(date)
  if (skipCurrent && localDate.getDay() === 1) {
    localDate = previous ? subDays(localDate, 1) : addDays(localDate, 1)
  }
  while (localDate.getDay() !== 1) {
    localDate = previous ? subDays(localDate, 1) : addDays(localDate, 1)
  }
  return localDate
}

export const getLongMonthString = (date: string | Date) =>
  new Date(date).toLocaleDateString('en-gb', { month: 'long' })

export const formatDatesToDelimitedString = (dates: Date[]) =>
  dates.map(date => getLocalDateString(date)).join(', ')

export const formatSickPayTakenHours = (value: number | undefined): string =>
  value ? `${value.toFixed(2)} Hours` : 'No'

const padTwoDigits = (num: number) => num.toString().padStart(2, '0')

export const dateInYyyyMmDdHhMmSs = (date: Date, dateDiveder = '-') =>
  `${[date.getFullYear(), padTwoDigits(date.getMonth() + 1), padTwoDigits(date.getDate())].join(
    dateDiveder
  )} ${[
    padTwoDigits(date.getHours()),
    padTwoDigits(date.getMinutes()),
    padTwoDigits(date.getSeconds()),
  ].join(':')}`

export const dateTimeToNumber = (date: Date | string): number => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return Number(format(date as Date, 'yyyyMMddHHmm'))
  }
  return Number(format(new Date(date), 'yyyyMMddHHmm'))
}

export const ddMMyyyy = 'dd/MM/yyyy'

interface TimeFormatOptions {
  locale: string
  options: Intl.DateTimeFormatOptions
}

const timeFormat: TimeFormatOptions = {
  locale: 'en-GB',
  options: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
}

export const formatTime = (date?: Date | null): string =>
  date?.toLocaleTimeString(timeFormat.locale, timeFormat.options) ?? ''

export const getDurationInFractionalHours = (start: Date, end: Date): number => {
  const minutesDifference = differenceInMinutes(end, start)
  const hoursDiffInFractions = minutesDifference / 60
  return +hoursDiffInFractions.toFixed(2)
}
export const isDurationMultipleOf30minutes = (start: Date, end: Date): boolean => {
  const minutesDifference = differenceInMinutes(end, start)
  return minutesDifference % 30 === 0
}

export const isWeekend = (date: Date): boolean => date.getDay() === 6 || date.getDay() === 0