import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RequestDay, RequestType } from '../../models'
import {
  SetShowModalPayload,
  hideHeaderDrawer,
  hideModal,
} from '../../redux/reducers/appSettingsReducer'
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../redux/reducers/snackbarReducer'
import {
  setAlerts,
  setComments,
  setConflicts,
  setDateRange,
  setEmployeeSelected,
  setEmployees,
  setFinancialYear,
  setHours,
  setIsIncrease,
  setSelectedDays,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { employeeService } from '../../services/employeeService'
import { manualRequestsService } from '../../services/myActionsService'
import { settingsService } from '../../services/settingsService'
import Modal from '../../shared/UI/Modal'
import Tab from '../../shared/UI/Tab'
import { AbsenceDayRequest } from '../../types/absence'
import { ConflictResponse } from '../../types/base-response'
import { OptionValue } from '../../types/option-value'
import { getCalendarRestrictions } from '../../utils/app-utils'
import { formatDateWithTimeZone, isWeekend } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import { ErrorList } from '../../utils/errorList'
import { tabType } from '../Absence/types'
import Adjustment from '../Adjustment'
import MultiDayRequest from '../MultiDayRequest'
import { EmployeeDetailsResponse } from '../../types/employee'

import { buildRequestType1, buildRequestType2, buildRequestType3 } from './manualRequestBuilder'
import {
  ManualRequestType1Request,
  ManualRequestType2Request,
  ManualRequestType3Request,
} from '../../types/manual-request-types'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { CallOutEnhancement } from '../CallOutEnhancement/CallOutEnhancement'
import { BuilderArgs, buildEnhancementPostBody } from '../../utils/RequestBuilder/requestBuilder'
import { usePayPeriod } from '../../utils/Hooks/usePayPeriod'
import { isEnhancementByRequestType } from '../../utils/SharedMethods/isEnhancement'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import { calculateCalloutDays } from '../../utils/SharedMethods/calculateCallOutDays'
import { ON_CALL_HOURS } from '../../utils/constants'
import {
  handleAbsenceHoursChange,
  handleEnhancementHoursChangeAbsenceDayRequest,
} from '../../utils/SharedMethods/handleHoursChange'

function ManualRequest() {
  const [isHidden, setIsHidden] = useState(true)
  const dispatch = useAppDispatch()
  const [defaultShiftLength, setDefaultShiftLength] = useState<number>(8)
  const [disabledDates, setDisabledDates] = useState<string[]>([])
  const [employeeDepartment, setEmployeeDepartment] = useState<OptionValue>()
  const [alertMessage, setAlertMessage] = useState<string | undefined>()
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const {
    dateRange,
    selectedDays,
    comments,
    adjustmentHours,
    isIncrease,
    financialYear,
    employeeSelected,
    requestType,
    conflicts,
    alerts,
    calloutDetails,
  } = useSelector<RootStore, timeOffRequestsState>((state: RootStore) => state.timeOff)
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  const defaultErrorHandler = useDefaultErrorHandler()
  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse | undefined>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const isHTL = useMemo(() => employeeDetails?.isHtl ?? false, [employeeDetails?.isHtl])
  const payPeriod = usePayPeriod()
  const getDayHours = useCallback(() => {
    if (isEnhancementByRequestType(requestType))
      return requestType === RequestType.ON_CALL || requestType === RequestType.ON_CALL_M
        ? ON_CALL_HOURS
        : 0
    return defaultShiftLength
  }, [requestType, defaultShiftLength])
  if (employeeSelected && !employeeDepartment) {
    employeeService
      .getEmployeeDepartment(employeeSelected)
      .then(employee => {
        setEmployeeDepartment(employee.department)
      })
      .catch(defaultErrorHandler)
  }

  useEffect(() => {
    if (!employeeDepartment) {
      return
    }
    settingsService
      .getRestrictions(employeeDepartment.value)
      .then(deptRestrictions => {
        setDisabledDates(getCalendarRestrictions(deptRestrictions, requestType))
      })
      .catch(defaultErrorHandler)
  }, [dispatch, employeeDepartment, requestType])

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const allDays: AbsenceDayRequest[] = []
      const currentDate = formatDateWithTimeZone(dateRange[0])
      if (requestType === RequestType.CALL_OUT_M) {
        allDays.push(...calculateCalloutDays(dateRange))
      } else {
        while (currentDate <= formatDateWithTimeZone(dateRange[1])) {
          const checked = isEnhancementByRequestType(requestType) || !isWeekend(new Date(currentDate))
          allDays.push({
            day: formatDateWithTimeZone(currentDate),
            hours: getDayHours(),
            checked,
          })
          currentDate.setDate(currentDate.getDate() + 1)
        }
      }
      dispatch(setSelectedDays(allDays))
      dispatch(setAlerts([]))
    }
  }, [dateRange])

  const calculateTotalDays = useCallback((): number => {
    if (selectedDays) {
      const filteredSelectedDays = selectedDays.filter((x: RequestDay) => x.checked)
      if (filteredSelectedDays.length > 0) {
        return filteredSelectedDays
          .map((selectedDay: RequestDay) =>
            !Number.isNaN(selectedDay.hours!) ? selectedDay.hours! : 0
          )
          .reduce((accumulator: any, currentValue: any) => {
            if (!accumulator) {
              return 0
            }
            if (!currentValue) {
              return accumulator
            }
            return accumulator + currentValue
          })
      }
    }
    return 0
  }, [selectedDays])

  let updatedDay: any

  const updateHours = (selectedDay: Date, hours: string, request: RequestType) => {
    if (selectedDays) {
      let updatedDays
      if (isEnhancementByRequestType(request)) {
        updatedDays = handleEnhancementHoursChangeAbsenceDayRequest(
          hours,
          selectedDay,
          selectedDays
        )
      } else {
        updatedDays = handleAbsenceHoursChange(hours, selectedDay, selectedDays)
      }
      dispatch(setSelectedDays(updatedDays as AbsenceDayRequest[]))
    }
  }

  const updateCheckedDay = (selectedDay: Date, hours: string) => {
    if (selectedDays) {
      updatedDay = selectedDays.find(
        (day: RequestDay) => day.day!.getTime() === selectedDay.getTime()
      )
      if (updatedDay) {
        updatedDay.checked = !updatedDay.checked
        dispatch(setSelectedDays([...selectedDays]))
      }
    }
  }

  const dispatchSubmission = () => {
    dispatch(showSuccessMessage(`Your ${tabType(requestType)} request has been sent`))
    dispatch(hideHeaderDrawer())
    dispatch(setDateRange([null, null]))
    dispatch(setSelectedDays(null))
    dispatch(setIsIncrease(false))
    dispatch(setHours(0))
    dispatch(setFinancialYear(''))
    dispatch(setEmployees(null))
    dispatch(setComments(''))
    dispatch(setEmployeeSelected(null))
  }

  const submitRequest = async (
    body: ManualRequestType1Request | ManualRequestType2Request | ManualRequestType3Request,
    event: React.FormEvent<HTMLFormElement>,
    callType: number
  ) => {
    event.preventDefault()
    setSubmitLoading(true)
    manualRequestsService
      .createManualRequest(body, callType)
      .then(e => {
        setSubmitLoading(false)
        if (e.status === 200 && e.errors.length > 0) {
          e.errors.forEach(error => {
            if (error.name === 'NegativeEntitlement') {
              const alertObj = ErrorList.find(err => err.name === 'NegativeEntitlement')
              dispatch(showInfoMessage(alertObj!.message))
              dispatch(setConflicts(true))
              return
            }
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        } else if (e.status === 201 && conflicts) {
          dispatchSubmission()
        } else {
          dispatchSubmission()
        }
      })
      .catch(err => {
        setSubmitLoading(false)
        if (err.response.status === 409) {
          dispatch(setAlerts((err.response.data as ConflictResponse).conflicts))
          dispatch(setConflicts(true))
        } else {
          defaultErrorHandler(err)
        }
      })
  }

  const getBuilderArgs = (): BuilderArgs => ({
    requestType,
    employeeId: employeeSelected!,
    dateRange,
    comments,
    selectedDays,
    financialYear,
    increase: isIncrease,
    hours: adjustmentHours,
    overrideConflicts: conflicts,
    alerts,
    conflicts,
    calloutDetails,
  })

  const submitType1 = async (event: React.FormEvent<HTMLFormElement>) => {
    await submitRequest(buildRequestType1(getBuilderArgs()), event, 1)
  }

  const submitType2 = async (event: React.FormEvent<HTMLFormElement>) => {
    await submitRequest(buildRequestType2(getBuilderArgs()), event, 2)
  }

  const submitType3 = async (event: React.FormEvent<HTMLFormElement>) => {
    await submitRequest(buildRequestType3(getBuilderArgs()), event, 3)
  }

  const submitEnhancement = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitLoading(true)
    v2MyActionsService
      .postEnhancementManual(buildEnhancementPostBody(getBuilderArgs()))
      .then(e => {
        setSubmitLoading(false)
        dispatchSubmission()
      })
      .catch(err => {
        setSubmitLoading(false)
        defaultErrorHandler(err)
      })
  }

  const handleClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      dispatch(hideHeaderDrawer())
      dispatch(setDateRange([null, null]))
      dispatch(setSelectedDays(null))
      dispatch(setIsIncrease(false))
      dispatch(setHours(0))
      dispatch(setFinancialYear(''))
      dispatch(setEmployees(null))
      dispatch(setComments(''))
    } catch (err) {
      dispatch(showErrorMessage('Something went wrong'))
    }
  }

  useEffect(() => {
    dispatch(setConflicts(false))
    if (employeeSelected) {
      setIsHidden(false)
      employeeService
        .getEmployeeDefaultShiftLength(employeeSelected)
        .then(response => {
          setDefaultShiftLength(response.defaultShiftLength)
        })
        .catch(defaultErrorHandler)
    } else {
      setIsHidden(true)
    }
  }, [employeeSelected, isIncrease, adjustmentHours, financialYear])

  let tabs = [
    {
      label: tabType(RequestType.ADJUSTMENT),
      tabContext: (
        <Adjustment
          type={RequestType.ADJUSTMENT}
          tabType={tabType}
          handleSubmit={submitType1}
          handleClose={(e: any) => handleClose(e)}
          isHidden={isHidden}
          allowIsHidden
        />
      ),
    },
    {
      label: tabType(RequestType.COURSE),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.COURSE)}
            type={RequestType.COURSE}
            handleSubmit={submitType2}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.LIEU_DAY),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.LIEU_DAY_M)}
            type={RequestType.LIEU_DAY_M}
            handleSubmit={submitType2}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.HOLIDAY),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.HOLIDAY_M)}
            type={RequestType.HOLIDAY_M}
            handleSubmit={submitType2}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            disabledDates={disabledDates}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.MATERNITY),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.MATERNITY)}
            type={RequestType.MATERNITY}
            handleSubmit={submitType3}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            doesIncludeSummary={false}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.PATERNITY),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.PATERNITY)}
            type={RequestType.PATERNITY}
            handleSubmit={submitType3}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            doesIncludeSummary={false}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.OTHER),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.OTHER)}
            type={RequestType.OTHER}
            handleSubmit={submitType2}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.NIGHT_SHIFT),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.SHIFT)}
            type={RequestType.NIGHT_SHIFT}
            handleSubmit={submitType3}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            doesIncludeSummary={false}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
    {
      label: tabType(RequestType.TWILIGHT_SHIFT),
      tabContext: (
        <Grid item xs={12} mt={5}>
          <MultiDayRequest
            cardTitle={tabType(RequestType.TWILIGHT_SHIFT)}
            type={RequestType.TWILIGHT_SHIFT}
            handleSubmit={submitType3}
            handleClose={(e: any) => handleClose(e)}
            calculateTotalDays={calculateTotalDays}
            updateHours={updateHours}
            updateCheckedDay={updateCheckedDay}
            doesIncludeSummary={false}
            isManager
            isHidden={isHidden}
            allowIsHidden
            isHTL={isHTL}
            submitLoading={submitLoading}
            dataTestId="ManualRequest"
          />
        </Grid>
      ),
    },
  ]
  if (enhancementsSettingOn) {
    tabs = [
      ...tabs,
      {
        label: tabType(RequestType.CALL_OUT),
        tabContext: (
          <Grid item xs={12} mt={5}>
            <CallOutEnhancement
              disabledDates={disabledDates}
              isManager
              isEmployeeSelected={!isHidden}
              handleSubmit={submitEnhancement}
              handleClose={handleClose}
              payPeriod={payPeriod}
            />
          </Grid>
        ),
      },
      {
        label: tabType(RequestType.ON_CALL),
        tabContext: (
          <Grid item xs={12} mt={5}>
            <MultiDayRequest
              cardTitle={tabType(RequestType.ON_CALL_M)}
              type={RequestType.ON_CALL_M}
              handleSubmit={submitEnhancement}
              handleClose={(e: any) => handleClose(e)}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              disabledDates={disabledDates}
              isManager
              isHidden={isHidden}
              allowIsHidden
              isHTL={isHTL}
              submitLoading={submitLoading}
              payPeriod={payPeriod}
            />
          </Grid>
        ),
      },
      {
        label: tabType(RequestType.OVERTIME),
        tabContext: (
          <Grid item xs={12} mt={5}>
            <MultiDayRequest
              cardTitle={tabType(RequestType.OVERTIME_M)}
              type={RequestType.OVERTIME_M}
              handleSubmit={submitEnhancement}
              handleClose={(e: any) => handleClose(e)}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              disabledDates={disabledDates}
              isManager
              isHidden={isHidden}
              allowIsHidden
              isHTL={isHTL}
              submitLoading={submitLoading}
              dataTestId="ManualRequest"
              payPeriod={payPeriod}
            />
          </Grid>
        ),
      },

      {
        label: tabType(RequestType.NIGHT),
        tabContext: (
          <Grid item xs={12} mt={5}>
            <MultiDayRequest
              cardTitle={tabType(RequestType.NIGHT_M)}
              type={RequestType.NIGHT_M}
              handleSubmit={submitEnhancement}
              handleClose={(e: any) => handleClose(e)}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              disabledDates={disabledDates}
              isManager
              isHidden={isHidden}
              allowIsHidden
              isHTL={isHTL}
              submitLoading={submitLoading}
              dataTestId="ManualRequest"
              payPeriod={payPeriod}
            />
          </Grid>
        ),
      },
    ]
  }
  return (
    <>
      <Tab titleAndContent={tabs} dataTestId="ManualRequest" />
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        title={title}
        message={alertMessage || message}
        buttonLabel={buttonLabel}
      />
    </>
  )
}

export default ManualRequest
