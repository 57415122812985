/* eslint-disable no-shadow */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { RootStore } from '../../redux/store'
import { EnhancementsSummaryTable } from './EnhancementSummary/EnhancementSummaryTable'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import { enhancementTypes, enhancementsContent } from '../../utils/constants'
import { enhancementService } from '../../services/enhancementService'
import {
  EnhancementApprovalViewResponse,
  EnhancementStub,
  SubmissionPeriod,
  SubmitStatus,
} from '../../models/enhancement'
import { enhancementFilter } from './enhancementSummaryFilter'
import { EmployeeDetailsResponse } from '../../types/employee'
import { hasUserAccessToRoute } from '../../utils/app-utils'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import { PayPeriodBanner } from './PayPeriodBanner/PayPeriodBanner'
import {
  TrendIndicator,
  TrendIndicatorProps,
  TrendIndicatorStats,
} from '../../shared/UI/TrendIndicatorCard/TrendIndicator'
import { trendIncdicatorThemes } from '../../shared/UI/TrendIndicatorCard/TrendStyles'
import { getStats } from './statsProvider'
import { showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import Modal from '../../shared/UI/Modal'

/*
 * Set the initial state of page data
 */
const pageURL = '/enhancements'

const defaultVals: EnhancementApprovalViewResponse = {
  enhancements: [],
  numberOfPendingEnhancements: 0,
  errors: [],
}

const defaultStats: TrendIndicatorStats = { percentage: 0, hours: 0 }

export function Enhancements() {
  const dispatch = useDispatch()
  const defaultErrorHandler = useDefaultErrorHandler()
  /*
   * Start feature flag code
   * Check if user has the permissions to view this page and the feature toggle is on
   */
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const year = searchParams.get('year')
  const period = searchParams.get('period')

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  // Check if user has the permissions to view this page and the feature toggle is on
    useEffect(() => {
        if (!userPermissions || !userSettings) {
            return
        }

        if (!hasUserAccessToRoute(pageURL, userPermissions, employeeDetails.isContractor, userSettings)) {
            navigate('/dashboard')
        }
    }, [employeeDetails.isContractor, userSettings, enhancementsSettingOn, navigate, userPermissions])
  /*
   * End feature flag code
   */

  const [lastResponse, setLastResponse] = useState<EnhancementApprovalViewResponse>(defaultVals)
  const [filteredEnhancements, setFilteredEnhancements] = useState<EnhancementStub[]>([])
  const filterArgs = useRef<string[]>([])
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const totalEnhancementsToSubmit = useMemo(() => lastResponse.enhancements.filter(
    enhancement => [SubmitStatus.APPROVED, SubmitStatus.AMENDED].includes(enhancement.submitStatus)
).length, [lastResponse])

  const enableModal = () => {
    setShowModal(true)
  }

  const enableSubmitButton = (response: EnhancementApprovalViewResponse) => {
    const { payPeriod, enhancements } = response

    if (!payPeriod) {
      return
    }

    let result = false

    const evaluateAndSetSubmitButtonState = () => {
      result = !payPeriod.submittedOn && enhancements.length > 0
      setSubmitButtonEnabled(result)
    }

    const now = new Date().getTime()

    const submitEnabledTime = new Date(payPeriod.submitEnabled).getTime()
    const submitDisabledTime = new Date(payPeriod.submitBy).getTime()
    const timeToWaitToEnable = submitEnabledTime - now
    const timetoWaitToDisable = submitDisabledTime - now

    const withinTimeRange = timeToWaitToEnable <= 0 && timetoWaitToDisable > 0
    const afterCutOffPeriod = timetoWaitToDisable <= 0
    const beforeEnableTime = timeToWaitToEnable > 0
    const beforeDisableTime = timetoWaitToDisable > 0

    if (beforeEnableTime) {
      setSubmitButtonEnabled(false)
      setTimeout(evaluateAndSetSubmitButtonState, timeToWaitToEnable)
    }
    if (beforeDisableTime) {
      setTimeout(() => setSubmitButtonEnabled(false), timetoWaitToDisable)
    }
    if (withinTimeRange) {
      evaluateAndSetSubmitButtonState()
    }
    if (afterCutOffPeriod) {
      setSubmitButtonEnabled(false)
    }
  }

  interface Stats {
    onCall?: TrendIndicatorStats
    calledOut?: TrendIndicatorStats
    overtime?: TrendIndicatorStats
    night?: TrendIndicatorStats
    total?: TrendIndicatorStats
  }

  const stats = useRef<Stats>({})

  const handleSuccessResponse = (response: EnhancementApprovalViewResponse) => {
    const { enhancements, enhancementStatsPreviousPeriod } = response
    const statsObject: Stats = {}
    if (enhancementStatsPreviousPeriod) {
      statsObject.calledOut = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.calledOut.hours,
        enhancementTypes.callOut
      )
      statsObject.night = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.night.hours,
        enhancementTypes.night
      )
      statsObject.onCall = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.onCall.hours,
        enhancementTypes.onCall
      )
      statsObject.overtime = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.overtime.hours,
        enhancementTypes.overtime
      )
      statsObject.total = getStats(enhancements, enhancementStatsPreviousPeriod?.total.hours, '')
    }
    stats.current = statsObject
    setLastResponse(response)
    setFilteredEnhancements(enhancementFilter(response.enhancements, filterArgs.current))
    enableSubmitButton(response)
  }

  const fetchApprovals = (submissionPeriod?: SubmissionPeriod) => {
    if (submissionPeriod?.year && submissionPeriod?.period) {
      navigate(
        {
          pathname: pageURL,
          search: `?${createSearchParams({
            year: submissionPeriod?.year.toString(),
            period: submissionPeriod?.period.toString(),
          })}`,
        },
        { replace: true }
      )
    }
    enhancementService
      .getApprovals(submissionPeriod)
      .then(handleSuccessResponse)
      .catch(defaultErrorHandler)
  }

  const submitToPayroll = () => {
    enhancementService
      .postApprovalsSubmit()
      .then(response => {
        handleSuccessResponse(response)
        dispatch(showSuccessMessage(enhancementsContent.submitToPayrollSuccessMessage))
      })
      .catch(defaultErrorHandler)
  }
  const filterEnhancements = (args: string | undefined) => {
    const array = args ? [args] : []

    filterArgs.current = array
    setFilteredEnhancements(enhancementFilter(lastResponse.enhancements, array))
  }

  const getApprovals = useCallback((submissionPeriod?: SubmissionPeriod): void => {
    fetchApprovals(submissionPeriod)
  }, [])

  useEffect(() => {
    if (year && period) {
      fetchApprovals({ year: Number(year), period: Number(period) })
    } else {
      fetchApprovals()
    }
  }, [])

  const navigateNext = () => {
    getApprovals(lastResponse.payPeriod?.nextPeriod)
  }

  const navigatePrevious = () => {
    getApprovals(lastResponse.payPeriod?.previousPeriod)
  }

  const isActive = (filterArg: string | undefined) => {
    if (filterArg) return filterArgs.current.includes(filterArg)
    return filterArgs.current?.length === 0
  }

  const filters: TrendIndicatorProps[] = [
    {
      testId: 'on-call',
      theme: trendIncdicatorThemes.onCall,
      filterArg: enhancementTypes.onCall,
      stats: stats.current.onCall ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'call-out',
      theme: trendIncdicatorThemes.callOut,
      filterArg: enhancementTypes.callOut,
      stats: stats.current.calledOut ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'overtime',
      theme: trendIncdicatorThemes.overtime,
      filterArg: enhancementTypes.overtime,
      stats: stats.current.overtime ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'night',
      theme: trendIncdicatorThemes.night,
      filterArg: enhancementTypes.night,
      stats: stats.current.night ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'total',
      theme: trendIncdicatorThemes.total,
      stats: stats.current.total ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
  ]

  const renderPayPeriodBanner = () => {
    if (!lastResponse.payPeriod) return
    const { submittedOn, submitBy, dateRange, currentPeriod, previousPeriod, nextPeriod } =
      lastResponse.payPeriod

    return (
      <PayPeriodBanner
        status={submittedOn ? 'submitted' : 'pending'}
        statusDate={submittedOn ?? submitBy}
        dateRange={dateRange}
        currentPayPeriod={currentPeriod?.period ?? 1}
        disableNext={!nextPeriod}
        disablePrevious={!previousPeriod}
        onNext={() => {
          navigateNext()
        }}
        onPrevious={() => {
          navigatePrevious()
        }}
      />
    )
  }

  const now = new Date().getTime()
  const isPeriodOpenForSubmissions = !!lastResponse.payPeriod?.submitEnabled
    && !!lastResponse.payPeriod.submitBy
    && now > new Date(lastResponse.payPeriod?.submitEnabled).getTime()
    && now < new Date(lastResponse.payPeriod?.submitBy).getTime()
    && !lastResponse.payPeriod.submittedOn

  return (
    <>
      {userPermissions?.length < 1 || !enhancementsSettingOn ? (
        <LoadingIndicator show />
      ) : (
        <>
          <Modal
            type="question"
            open={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            onClick={() => {
              submitToPayroll()
              setShowModal(false)
            }}
            title="Are you sure?"
            message={enhancementsContent.submitToPayrollPreSubmitMessage}
            buttonLabel="Confirm"
            isDisabled={false}
            maxWidth="xl"
          />
          <MyActionsPageHeader title={enhancementsContent.enhancements} />
          {renderPayPeriodBanner()}
          <Box
            mb="24px"
            display="flex"
            padding="24px 6px"
            sx={{
              overflowX: 'auto',
            }}
          >
            {filters.map(filter => (
              <TrendIndicator {...filter} key={filter.filterArg} />
            ))}
          </Box>
          <EnhancementsSummaryTable
            data={filteredEnhancements}
            handleSubmitToPayroll={enableModal}
            currentPeriod={lastResponse.payPeriod?.currentPeriod}
            disableSubmitButton={!submitButtonEnabled}
            isPeriodOpenForSubmissions={isPeriodOpenForSubmissions}
            totalEnhancementsToSubmit={totalEnhancementsToSubmit}
            pendingEnhancements={lastResponse.numberOfPendingEnhancements || 0}
          />
        </>
      )}
    </>
  )
}
