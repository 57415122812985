import { Grid } from '@mui/material'
import { format } from 'date-fns'
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageContext as MyRequestsPageContext } from '../../context/MyRequestsPageContext'
import { RequestType, RequestTypeNameString } from '../../models'
import { RequestDay } from '../../models/request-day'
import {
  SetShowModalPayload,
  hideModal,
  hideMyRequestsDrawer,
} from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import {
  resetNewRequestState,
  setAlerts,
  setComments,
  setConflicts,
  setDateRange,
  setDefaultDateRange,
  setSelectedDays,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { requestsService } from '../../services/requestsService'
import { settingsService } from '../../services/settingsService'
import Alert from '../../shared/UI/Alert/Alert'
import Modal from '../../shared/UI/Modal'
import Tab, { TabDetails } from '../../shared/UI/Tab'
import { AbsenceDayRequest } from '../../types/absence'
import { BaseResponse, ConflictResponse } from '../../types/base-response'
import { EmployeeDetailsResponse } from '../../types/employee'
import { SettingsMaintenanceRequest } from '../../types/settings-maintenance-request'
import { getCalendarRestrictions } from '../../utils/app-utils'
import { absoluteDate, formatDateWithTimeZone, isWeekend } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import { tabType } from '../Absence/types'
import DayOffRequest from '../DayOffRequest'
import MultiDayRequest from '../MultiDayRequest'
import { useEmployeeDefaultShiftLength } from '../MyRequests/useEmployeeDefaultShiftLength'
import { ApiHolidayRestrictions } from '../SettingsOptions/settingsTypes'
import ShiftRequest from '../ShiftRequest'
import BirthdayRequest from '../BirthdayRequest'

import { CallOutEnhancement } from '../CallOutEnhancement/CallOutEnhancement'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { BuilderArgs, buildEnhancementPostBody } from '../../utils/RequestBuilder/requestBuilder'
import {
  buildCreateDayOffRequest,
  buildCreateMultiDayRequest,
  buildCreateShiftRequest,
} from './newRequestBuilder'
import { usePayPeriod } from '../../utils/Hooks/usePayPeriod'
import { isEnhancementByRequestType } from '../../utils/SharedMethods/isEnhancement'
import { calculateCalloutDays } from '../../utils/SharedMethods/calculateCallOutDays'
import { ON_CALL_HOURS } from '../../utils/constants'
import {
  handleAbsenceHoursChange,
  handleEnhancementHoursChangeAbsenceDayRequest,
} from '../../utils/SharedMethods/handleHoursChange'

const componentTestId = 'Dashboard-NewRequest'
interface Props {
  onClose: (event?: React.FormEvent<HTMLFormElement>, reason?: string) => void
  propEmployeeDefaultShiftLength?: number
}

const getRestrictedDates = (start: Date, end: Date) => {
  const restrictedDatesArray = []
  for (
    const restrictedDate = new Date(start);
    restrictedDate <= new Date(end);
    restrictedDate.setDate(restrictedDate.getDate() + 1)
  ) {
    restrictedDatesArray.push(new Date(restrictedDate))
  }
  return restrictedDatesArray
}

function NewRequest({ onClose, propEmployeeDefaultShiftLength }: Props) {
  const [restrictions, setRestrictions] = useState<ApiHolidayRestrictions[] | undefined>([])
  const dispatch = useAppDispatch()
  const [disabledDates, setDisabledDates] = useState<string[]>([])
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const { getRequestsData } = useContext(MyRequestsPageContext)
  const { employeeDefaultShiftLength } = useEmployeeDefaultShiftLength()
  const [hasRestrictions, setHasRestrictions] = useState<boolean>(false)
  const [responseTypes, setResponseTypes] = useState<SettingsMaintenanceRequest>()
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  const payPeriod = usePayPeriod()
  const {
    dateRange,
    selectedDays,
    comments,
    requestType,
    selectedDate,
    conflicts,
    alerts,
    calloutDetails,
  } = useSelector<RootStore, timeOffRequestsState>((state: RootStore) => state.timeOff)

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse | undefined>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const isHTL = useMemo(() => employeeDetails?.isHtl ?? false, [employeeDetails?.isHtl])

  const { birthdayRequest } = useSelector((state: RootStore) => state.featureToggles)

  const getRequestName = useCallback((reqType: RequestType): string => {
    switch (reqType) {
      case RequestType.HOLIDAY:
        return 'Holiday requests'
      case RequestType.DAY_OFF:
        return 'Day Off requests'
      case RequestType.SHIFT:
        return 'Shift requests'
      case RequestType.LIEU_DAY:
        return 'Lieu Day requests'
      case RequestType.WORK_FROM_HOME:
        return 'Work From Home requests'
      case RequestType.BIRTHDAY:
        return 'Birthday requests'
      default:
        return 'Requests'
    }
  }, [])

  useEffect(() => {
    setResponseTypes(userSettings as SettingsMaintenanceRequest)
  }, [userSettings])

  useEffect(() => {
    if (!employeeDetails) {
      return
    }

    settingsService
      .getRestrictions(employeeDetails.departmentId)
      .then(deptRestrictions => {
        const teamRestrictions = {
          holidayRestrictions: deptRestrictions.holidayRestrictions.filter(
            x => x.departmentTeamId === employeeDetails.teamId
          ),
          errors: deptRestrictions.errors,
        }

        setDisabledDates(getCalendarRestrictions(teamRestrictions, requestType))
        setRestrictions(
          deptRestrictions.holidayRestrictions.filter(
            x => x.departmentTeamId === employeeDetails.teamId
          )
        )
      })
      .catch(err => {
        setRestrictions(undefined)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, employeeDetails, requestType])

  useEffect(() => {
    dispatch(setDateRange([null, null]))
  }, [dispatch])

  const isDateRestricted = (day: Date) =>
    disabledDates?.some(disabledDate => {
      const disabledDay = new Date(disabledDate.replace('-', '/').replace('-', '/'))
      return disabledDay.toISOString() === day.toISOString()
    })
  const getDayHours = useCallback(
    (currentDate: Date) => {
      if (isDateRestricted(currentDate)) return 0
      if (isEnhancementByRequestType(requestType))
        return requestType === RequestType.ON_CALL || requestType === RequestType.ON_CALL_M
          ? ON_CALL_HOURS
          : 0
      return propEmployeeDefaultShiftLength || employeeDefaultShiftLength
    },
    [requestType, employeeDefaultShiftLength, propEmployeeDefaultShiftLength]
  )
  useEffect(() => {
    if ((dateRange && dateRange[0] && dateRange[1]) || selectedDate) {
      const allDays: AbsenceDayRequest[] = []
      const currentDate = dateRange[0] ? new Date(dateRange[0]) : new Date(selectedDate!)
      if (requestType === RequestType.CALL_OUT) {
        allDays.push(...calculateCalloutDays(dateRange))
      } else {
        while (currentDate <= dateRange[1]! || currentDate <= selectedDate!) {
          const checked = isDateRestricted(currentDate)
            ? false
            : isEnhancementByRequestType(requestType) || !isWeekend(new Date(currentDate))
          allDays.push({
            day: formatDateWithTimeZone(currentDate),
            hours: getDayHours(currentDate),
            checked
          })
          currentDate.setDate(currentDate.getDate() + 1)
        }
      }
      dispatch(setSelectedDays(allDays))
    } else {
      dispatch(setSelectedDays(null))
    }
  }, [
    dateRange,
    selectedDate,
    dispatch,
    employeeDefaultShiftLength,
    employeeDetails,
    employeeDetails?.employeeId,
    propEmployeeDefaultShiftLength,
  ])

  const isDayRestricted = (day: RequestDay) =>
    day.day && isDateRestricted(day.day)

  const calculateTotalDays = (): number => {
    if (selectedDays?.some(day => day.checked)) {
      const daysToCalculate = selectedDays.filter(x => x.checked)
      if (daysToCalculate.length === 0) {
        return 0
      }
      const selectedHours = selectedDays
        .filter(x => x.checked && !isDayRestricted(x))
        .map(selectedDay => (!Number.isNaN(selectedDay.hours!) ? selectedDay.hours! : 0))

      if (selectedHours.length) {
        return selectedHours.reduce((accumulator, currentValue) => {
          if (!accumulator && accumulator !== 0) {
            return 0
          }
          if (!currentValue) {
            return accumulator
          }
          return accumulator + currentValue
        })
      }
    }
    return 0
  }

  const updateHours = (selectedDay: Date, hours: string, request: RequestType) => {
    if (selectedDays) {
      let updatedDays
      if (isEnhancementByRequestType(request)) {
        updatedDays = handleEnhancementHoursChangeAbsenceDayRequest(
          hours,
          selectedDay,
          selectedDays
        )
      } else {
        updatedDays = handleAbsenceHoursChange(hours, selectedDay, selectedDays)
      }
      dispatch(setSelectedDays(updatedDays as AbsenceDayRequest[]))
    }
  }

  const updateCheckedDay = (selectedDay: Date, hours: string) => {
    if (selectedDays) {
      const updatedDay = selectedDays.find(day => day.day!.getTime() === selectedDay.getTime())
      if (updatedDay) {
        updatedDay.checked = !updatedDay.checked
        dispatch(setSelectedDays(selectedDays))
      }
    }
  }

  const hideAndResetDrawerState = (
    event?: FormEvent<HTMLFormElement> | undefined,
    submission = true
  ) => {
    dispatch(setDefaultDateRange(undefined))
    dispatch(setComments(''))
    dispatch(hideMyRequestsDrawer())
    if (submission) {
      getRequestsData()
    }
    dispatch(resetNewRequestState())
    // TODO: Redux has been used in a number of places in different guises in a bid to control drawer visibility, but none seem to be wired up properly.
    //  So I have re-applied the original onClose event as a temporary measure until the Redux implementation is properly implemented.
    onClose()
  }

  const checkMultiDayRequestNotRestricted = (selectedDates: string[]) => {
    selectedDays?.forEach(userSelectedDate => {
      if (
        userSelectedDate.checked &&
        !selectedDates.includes(format(userSelectedDate.day, 'yyyy-MM-dd'))
      ) {
        selectedDates.push(format(userSelectedDate.day, 'yyyy-MM-dd'))
      }
    })
  }

  const checkSingleDayRequestNotRestricted = (selectedDates: string[]) => {
    if (!selectedDate) {
      return
    }

    if (!selectedDates.includes(format(selectedDate, 'yyyy-MM-dd'))) {
      selectedDates.push(format(selectedDate, 'yyyy-MM-dd'))
    }
  }

  const checkRestrictionDateConflicts = (
    restrictionsByType: ApiHolidayRestrictions[] | undefined,
    isSingleDate = false
  ): boolean => {
    const restrictedDatesComplete = restrictionsByType?.map(restriction =>
      getRestrictedDates(restriction.dateFrom, restriction.dateTo)
    )

    const allRestrictedDates: Date[] = []

    restrictedDatesComplete?.map(restrictedDateRange =>
      allRestrictedDates.push(...restrictedDateRange)
    )

    const restrictedDates: string[] = []

    allRestrictedDates.forEach(restrictedDate => {
      if (!restrictedDates.includes(format(restrictedDate, 'yyyy-MM-dd'))) {
        restrictedDates.push(format(restrictedDate, 'yyyy-MM-dd'))
      }
    })

    const selectedDates: string[] = []

    if (isSingleDate) {
      checkSingleDayRequestNotRestricted(selectedDates)
    } else {
      checkMultiDayRequestNotRestricted(selectedDates)
    }

    const result: Date[] = []

    restrictedDates?.forEach(restrictedDate => {
      if (selectedDates.includes(restrictedDate)) {
        result.push(new Date(restrictedDate))
      }
    })

    return result && result.length === 0
  }

  const handleErrors = (err: any) => {
    setSubmitLoading(false)
    if (err.response.status === 409) {
      dispatch(setAlerts((err.response.data as ConflictResponse).conflicts))
      dispatch(setConflicts(true))
    } else {
      const response: BaseResponse = err.response.data
      response.errors.forEach(error => {
        dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
      })
    }
  }

  const handleSuccess = (response: BaseResponse, successMessage: string) => {
    setSubmitLoading(false)
    if (response.status === 200 && response.errors.length > 0) {
      response.errors.forEach(error => {
        dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
      })
    } else {
      dispatch(showSuccessMessage(successMessage))
      hideAndResetDrawerState()
    }
  }

  const checkRestrictions = (
    hasRequestRestrictions: ApiHolidayRestrictions[] | undefined,
    isSingleDate = false
  ): boolean => {
    if (restrictions === undefined) {
      dispatch(showErrorMessage('Something went wrong'))
      return false
    }

    const canSubmit =
      (restrictions && restrictions.length === 0) ||
      checkRestrictionDateConflicts(hasRequestRestrictions, isSingleDate)

    if (!canSubmit) {
      showErrorMessage(`${getRequestName(requestType)} are restricted for this date range`)
      return false
    }

    return true
  }

  const getBuilderArgs = (): BuilderArgs => ({
    requestType,
    dateRange,
    comments,
    selectedDays,
    overrideConflicts: conflicts,
    alerts,
    conflicts,
    selectedDate,
    employeeId: 0,
    financialYear: '',
    increase: false,
    hours: 0,
    calloutDetails,
  })

  const submitHolidayRequest = () => {
    if (checkRestrictions(restrictions?.filter(e => e.restrictHoliday)))
      requestsService
        .createHolidayRequest(buildCreateMultiDayRequest(getBuilderArgs()))
        .then(response => {
          handleSuccess(response, `Your Holiday request has been sent`)
        })
        .catch(err => handleErrors(err))
  }

  const submitDayOffRequest = () => {
    if (
      checkRestrictions(
        restrictions?.filter(e => e.restrictDayOff),
        true
      )
    )
      requestsService
        .createDayoffRequest(buildCreateDayOffRequest(getBuilderArgs()))
        .then(response => {
          handleSuccess(response, `Your Day-off request has been sent`)
        })
        .catch(err => handleErrors(err))
  }

  const submitLieuDayRequest = () => {
    if (checkRestrictions(restrictions?.filter(e => e.restrictLieuDay)))
      requestsService
        .createLieuDayRequest(buildCreateMultiDayRequest(getBuilderArgs()))
        .then(response => {
          handleSuccess(response, `Your Lieu day request has been sent`)
        })
        .catch(err => handleErrors(err))
  }

  const submitShiftRequest = () => {
    if (
      checkRestrictions(
        restrictions?.filter(e => e.restrictShift),
        true
      )
    )
      requestsService
        .createShiftRequest(buildCreateShiftRequest(getBuilderArgs()))
        .then(response => {
          handleSuccess(response, `Your Shift request has been sent`)
        })
        .catch(err => handleErrors(err))
  }

  const submitWFHRequest = () => {
    if (checkRestrictions(restrictions?.filter(e => e.restrictWFH)))
      requestsService
        .createWFHRequest(buildCreateMultiDayRequest(getBuilderArgs()))
        .then(response => {
          handleSuccess(response, `Your Work from home request has been sent`)
        })
        .catch(err => handleErrors(err))
  }

  const submitEnhancementRequest = () => {
    const { employeeId, ...payload } = buildEnhancementPostBody(getBuilderArgs())
    v2MyActionsService
      .postEnhancement(payload)
      .then(response => {
        handleSuccess(response, `Your Enhancement request has been sent`)
      })
      .catch(err => handleErrors(err))
  }

  const handleRestrictions = () => {
    if (selectedDays) {
      const restrictionsFound = selectedDays?.some(entry => {
        const disabledDate = disabledDates.find(date => date === absoluteDate(entry.day))
        return absoluteDate(entry.day) === disabledDate
      })
      setHasRestrictions(restrictionsFound)
    } else if (selectedDate) {
      const restrictionsFound = disabledDates.some(
        date => date === selectedDate.toLocaleDateString()
      )
      setHasRestrictions(restrictionsFound)
    } else {
      return setHasRestrictions(false)
    }
  }
  const defaultRequestTypeId = 0
  const requestTypeString = RequestTypeNameString[requestType ?? defaultRequestTypeId]
  const tabDataId = `Dashboard-NewRequest-${requestTypeString}`

  useEffect(() => {
    handleRestrictions()
  }, [selectedDays, selectedDate, restrictions])

  const titleAndContentsArray = () => {
    const contentArray: TabDetails[] = []
    if (responseTypes?.isHolidayRequestAllowed) {
      contentArray.push({
        label: 'Holiday',
        tabContext: (
          <Grid item xs={12} mt={4}>
            {hasRestrictions && (
              <Alert
                severity="error"
                message="Holiday requests are restricted for this date range"
              />
            )}
            <MultiDayRequest
              type={RequestType.HOLIDAY}
              cardTitle="Holiday"
              disabledDates={disabledDates}
              handleSubmit={submitHolidayRequest}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              handleClose={hideAndResetDrawerState}
              isHTL={isHTL}
              submitLoading={submitLoading}
              dataTestId={componentTestId}
            />
          </Grid>
        ),
      })
    }
    if (responseTypes?.isDayOffRequestAllowed) {
      contentArray.push({
        label: 'Day Off',
        tabContext: (
          <>
            {hasRestrictions && (
              <Alert
                severity="error"
                message="Day off requests are restricted for this date range"
              />
            )}
            <DayOffRequest
              handleSubmit={submitDayOffRequest}
              isHTL={isHTL}
              disabledDates={disabledDates}
              onClose={() => onClose()}
              submitLoading={submitLoading}
              requestTypeId={requestType}
            />
          </>
        ),
        disabled: false,
      })
    }
    if (responseTypes?.isShiftRequestAllowed) {
      contentArray.push({
        label: 'Shift',
        tabContext: (
          <>
            {hasRestrictions && (
              <Alert severity="error" message="Shift requests are restricted for this date range" />
            )}
            <ShiftRequest
              handleSubmit={submitShiftRequest}
              isHTL={isHTL}
              disabledDates={disabledDates}
              onClose={() => onClose()}
              submitLoading={submitLoading}
              requestTypeId={requestType}
            />
          </>
        ),
        disabled: false,
      })
    }
    if (responseTypes?.isLieuDayRequestAllowed) {
      contentArray.push({
        label: tabType(RequestType.LIEU_DAY),
        tabContext: (
          <Grid item xs={12} mt={4}>
            {hasRestrictions && (
              <Alert
                severity="error"
                message="Lieu day requests are restricted for this date range"
              />
            )}
            <MultiDayRequest
              type={RequestType.LIEU_DAY}
              cardTitle="Lieu Day"
              disabledDates={disabledDates}
              handleSubmit={submitLieuDayRequest}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              handleClose={hideAndResetDrawerState}
              isHTL={isHTL}
              submitLoading={submitLoading}
              dataTestId={componentTestId}
            />
          </Grid>
        ),
        disabled: false,
      })
    }
    if (responseTypes?.isWfhRequestAllowed) {
      contentArray.push({
        label: tabType(RequestType.WORK_FROM_HOME),
        tabContext: (
          <Grid item xs={12} mt={4}>
            {hasRestrictions && (
              <Alert
                severity="error"
                message="Work from home requests are restricted for this date range"
              />
            )}
            <MultiDayRequest
              type={RequestType.WORK_FROM_HOME}
              cardTitle="Work From Home"
              disabledDates={disabledDates}
              handleSubmit={submitWFHRequest}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              handleClose={hideAndResetDrawerState}
              isHTL={isHTL}
              submitLoading={submitLoading}
              dataTestId={componentTestId}
            />
          </Grid>
        ),
        disabled: false,
      })
    }
    if (responseTypes?.isBirthdayRequestAllowed && birthdayRequest) {
      contentArray.push({
        label: tabType(RequestType.BIRTHDAY),
        tabContext: (
          <Grid item xs={12} mt={4}>
            {hasRestrictions && (
              <Alert
                severity="error"
                message="Birthday requests are restricted for this date range"
              />
            )}
            <BirthdayRequest
              type={RequestType.BIRTHDAY}
              closeDrawer={hideAndResetDrawerState}
              requestTypeId={tabDataId}
            />
          </Grid>
        ),
        disabled: false,
      })
    }
    if (enhancementsSettingOn) {
      contentArray.push(
        {
          label: 'Call Out',
          tabContext: (
            <Grid item xs={12} mt={4}>
              {hasRestrictions && (
                <Alert
                  severity="error"
                  message="Call Out requests are restricted for this date range"
                />
              )}
              <CallOutEnhancement
                handleSubmit={submitEnhancementRequest}
                disabledDates={disabledDates}
                handleClose={hideAndResetDrawerState}
                payPeriod={payPeriod}
              />
            </Grid>
          ),
        },
        {
          label: 'On Call',
          tabContext: (
            <Grid item xs={12} mt={4}>
              {hasRestrictions && (
                <Alert
                  severity="error"
                  message="On Call requests are restricted for this date range"
                />
              )}
              <MultiDayRequest
                type={RequestType.ON_CALL}
                cardTitle="On Call"
                disabledDates={disabledDates}
                handleSubmit={submitEnhancementRequest}
                calculateTotalDays={calculateTotalDays}
                updateHours={updateHours}
                updateCheckedDay={updateCheckedDay}
                handleClose={hideAndResetDrawerState}
                isHTL={isHTL}
                submitLoading={submitLoading}
                dataTestId={componentTestId}
                payPeriod={payPeriod}
              />
            </Grid>
          ),
        },
        {
          label: 'Night',
          tabContext: (
            <Grid item xs={12} mt={4}>
              {hasRestrictions && (
                <Alert
                  severity="error"
                  message="Night requests are restricted for this date range"
                />
              )}
              <MultiDayRequest
                type={RequestType.NIGHT}
                cardTitle="Night"
                disabledDates={disabledDates}
                handleSubmit={submitEnhancementRequest}
                calculateTotalDays={calculateTotalDays}
                updateHours={updateHours}
                updateCheckedDay={updateCheckedDay}
                handleClose={hideAndResetDrawerState}
                isHTL={isHTL}
                submitLoading={submitLoading}
                dataTestId={componentTestId}
                payPeriod={payPeriod}
              />
            </Grid>
          ),
        },
        {
          label: 'Overtime',
          tabContext: (
            <Grid item xs={12} mt={4}>
              {hasRestrictions && (
                <Alert
                  severity="error"
                  message="Overtime requests are restricted for this date range"
                />
              )}
              <MultiDayRequest
                type={RequestType.OVERTIME}
                cardTitle="Overtime"
                disabledDates={disabledDates}
                handleSubmit={submitEnhancementRequest}
                calculateTotalDays={calculateTotalDays}
                updateHours={updateHours}
                updateCheckedDay={updateCheckedDay}
                handleClose={hideAndResetDrawerState}
                isHTL={isHTL}
                submitLoading={submitLoading}
                dataTestId={componentTestId}
                payPeriod={payPeriod}
              />
            </Grid>
          ),
        }
      )
    }
    return contentArray
  }

  return (
    <>
      <Tab titleAndContent={titleAndContentsArray()} dataTestId={componentTestId} />
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </>
  )
}

export default NewRequest
