import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { Box, Grid, Stack, Slide } from '@mui/material'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Desk as DeskIcon, Today as TodayIcon } from '@mui/icons-material'
import { format, isSameMonth, startOfMonth, subMinutes } from 'date-fns'
import Card from '../../shared/layout/Card'
import PageHeader from '../../shared/layout/PageHeader/PageHeader'
import { bookingService } from '../../services/booking/bookingService'
import {
  BookingBlockBookingPostBody,
  BookingBlockBooking,
  MostUsedDesk,
} from '../../services/booking/types'
import { RootStore } from '../../redux/store'
import { NavBar } from './Shared/NavBar'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { BaseResponse } from '../../types/base-response'
import { dateToNumber } from './utils/utils'
import BookingErrorMessage from './utils/BookingErrorMessage'
import BookingGridView from './Shared/BookingGridView'
import BookingScrollView from './Shared/BookingScrollView'
import BookingSearch from './Shared/BookingSearch'
import BookingFloorplanView from './Shared/BookingFloorplanView'
import BookingFloorplanFilter from './Shared/BookingFloorplanFilter'
import {
  setBookingFloorplanViewingDate,
  setDeskBookingFloorplansState,
  setDeskBookingLoading,
  setDeskBookingLocationsState,
  setDeskBookingShowMeBookingID,
  setDeskBookingShowFloorplan,
  setDeskBookingShowGridView,
  setDeskBookingFloorplanManuallySelected,
  setBookingGridViewingDate,
  setDeskBookingDashboardResults,
  setDeskBookingZonesState,
  setForwardDeskBookingsCount,
  setBookingSliderPosition,
  setInitialise,
  setMostUsedDesk,
  setDeskBookingInfinityBookingsResults,
} from '../../redux/reducers/deskBookingReducer'
import Modal from '../../shared/UI/Modal'
import { BookingCancellationProps } from './Shared/types'
import { BookingTodaysDesk } from './Shared/BookingTodaysDesk'
import { BookingShowMe } from './Shared/BookingShowMe'
import { BookingGeneralDesksInfo } from './Shared/BookingGeneralDesksInfo'
import {
  getDeskNameWithRow,
  getDashboardBookings,
  handleCancellation,
  handleCheckIn,
  refreshMostUsedDesk,
  refreshForwardBookingsCount,
  wizardSteps,
  onBookingGridViewDataLoaded,
  handleSearch,
} from './bookingLogic'
import { WizardSteps } from '../../shared/UI/WizardSteps'
import ButtonSmall from '../../shared/UI/ButtonSmall'
import CardTitleSub from '../../shared/UI/CardTitleSub'
import CardTitle from '../../shared/UI/CardTitle'
import { setBookingWizardStepState } from '../../redux/reducers/deskBookingWizardReducer'
import { BookingBlockStatus } from './BlockBooking/BookingBlockStatus'
import { BookingWizardSteps } from './BlockBooking/enums'
import { showComponents } from './utils'
import { BookingBlockSummary } from './BlockBooking/BookingBlockSummary'
import { MONTHS } from '../../utils/constants'
import { BookingDateRange } from './consts'
import Paragraph from '../../shared/UI/Paragraph'
import {
  BookingSearchType,
  BookingNotificationError,
  BookingWizardStepLabels,
  BookingStatus,
  BookingSliderIndex,
  BookingZIndexLayers
} from './Shared/enums'
import {
  setDeskBookingSearchParams,
  setDeskBookingSearchResults,
  setBookingSearchRecentParams,
} from '../../redux/reducers/deskBookingSearchReducer'
import { formatDatTimeWithTimeZoneStr, formatDateWithTimeZone } from '../../utils/date-utils'
import { BookingSearchParams } from './types'
import { BookingGridViewToggle } from './Shared/BookingGridViewToggle'

const {
  BOOKING_BLOCK_FEATURE_MIN_NAV_OVERLAY,
  BOOKING_DASHBOARD_FEATURE_GRID_VIEW_BLEND
} = BookingZIndexLayers

export default function Booking() {

  const {
    mobileDetection
  } = useSelector((state: RootStore) => state.responsiveDetection)

  const { isMobileLayout } = mobileDetection


  const abortControllerRef = useRef<AbortController | null>(null)

  const [mobileRedesignDrop3] = useState<boolean>(true)

  const [mobileSearch, setMobileSearch] = useState<boolean>(false)
  const containerRef = useRef<HTMLElement>(null)

  useEffect(
    () => () => {
      // Cleanup function to abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    },
    []
  )

  const [showConfirm, setShowConfirm] = useState<{ message: string; open: boolean }>({
    message: '',
    open: false,
  })
  const [cancellationParams, setCancellationParams] = useState<BookingCancellationProps | null>(
    null
  )
  const [forwardBookingsPreviousDisplayValue, setForwadBookingsPreviousDisplayValue] = useState<
    number| undefined
  >()

  const [previousMostUsedDeskName, setPreviousMostUsedDeskName] = useState<string | undefined>()
  const dispatch = useDispatch()
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const {
    currentStep: wizardCurrentStep,
    selectedFeature,
    finalSelection,
  } = useSelector((state: RootStore) => state.deskBookingWizard)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const {
    locations,
    floorplans,
    featuresForFloorPlan,
    showGridView,
    showFloorplan,
    showNavBar,
    showSearch,
    showFilter,
    showBlockBookingWizard,
    showBlockBookingSummary,
    zones,
    infinityBookingsResults,
    dashboardResults,
    gridViewingDate,
    todaysBooking,
    showMeBookingID,
    forwardDeskBookingsCount,
    mostUsedDesk,
  } = useSelector((state: RootStore) => state.deskBooking)
  const { searchResults, searchParams, searchRecentParams } = useSelector(
    (state: RootStore) => state.deskBookingSearch
  )

  const {
    bookingRange,
  } = useSelector((state: RootStore) => state.deskBookingWizard)

  const getDeskBookingDetails = (bookingId: number) => {

    const getDashboardResults = dashboardResults?.results
    const hasDashboardResults = getDashboardResults !== undefined

    const combinedBookings = [
      ...searchResults,
      ...(hasDashboardResults ? getDashboardResults : [])
    ]

    return combinedBookings.find(f => f.statusId === BookingStatus.ACCEPTED && f.id === bookingId)
  }

  const getFirstNameFromBookingId = (bookingId: number) => {
    const bookingDisplayName = getDeskBookingDetails(bookingId)?.displayName || 'Desk-User'
    return bookingDisplayName?.split(' ')[0] ?? bookingDisplayName
  }

  const isOwnDesk = (bookingId: number) => {
    const booking = getDeskBookingDetails(bookingId)
    return booking?.employeeId === employeeDetails.employeeId
  }

  const [loadBookingsOnce, setLoadBookingsOnce] = useState<boolean>(false)
  const initialized = useRef(false)

  const getLatestEmployeeDashboardBookings = (infinityTrigger?: boolean) => {
    const singleDayBookingQuery = false
    const inifnityMobileAutoUpdayeMode = infinityTrigger ?? false
    getDashboardBookings(
      employeeDetails.employeeId,
      new Date(),
      dispatch,
      onBookingGridViewDataLoaded,
      singleDayBookingQuery,
      abortControllerRef,
      undefined,
      inifnityMobileAutoUpdayeMode,
      inifnityMobileAutoUpdayeMode
    )
  }

  const resetViewToDeskBookingsDashboard = () => batch(() => {

    dispatch(setBookingGridViewingDate(startOfMonth(new Date())))
    dispatch(setDeskBookingFloorplanManuallySelected(false))

    if (!showGridView) {

      dispatch(setDeskBookingShowGridView(true))
      dispatch(setDeskBookingShowFloorplan(false))

      showComponents(dispatch, { gridview: true, floorplan: false, navbar: true, search: true, filter: false })

      dispatch(setBookingSliderPosition({
        slidePosition: BookingSliderIndex.GRID_VIEW,
        buttonClick: false
      }))
      dispatch(setDeskBookingDashboardResults([]))
      dispatch(setDeskBookingLoading(true))

      getLatestEmployeeDashboardBookings()
    }
  })

  const loadingBookingsOnce = () => {
    if (initialized.current || loadBookingsOnce) {
      return
    }

    dispatch(setDeskBookingLoading(true))

    setLoadBookingsOnce(true)
    initialized.current = true

    getLatestEmployeeDashboardBookings(isMobileLayout)
  }

  const shouldFetchEmployeeDashboardBookings = useCallback(() => {
    const hasEmployee = employeeDetails && employeeDetails.employeeId !== 0
    const hasDashboardResults = dashboardResults?.results !== undefined
    return hasEmployee && !hasDashboardResults && !loadBookingsOnce
  }, [employeeDetails, mostUsedDesk, loadBookingsOnce])

  useEffect(() => {
    if (!shouldFetchEmployeeDashboardBookings()) {
      return
    }

    loadingBookingsOnce()
  }, [dispatch, employeeDetails.employeeId, shouldFetchEmployeeDashboardBookings])

  useEffect(() => {
    dispatch(setInitialise())

    if (shouldFetchEmployeeDashboardBookings()) {
      dispatch(setDeskBookingLoading(true))

      setTimeout(() => loadingBookingsOnce(), 13)
    }

    return () => {
      initialized.current = true
      setLoadBookingsOnce(true)
    }
  }, [])

  const [storedLocation, setStoredLocation] = useState<string>('')
  const [storedLocationFloor, setStoredLocationFloor] = useState<string>('')

  useEffect(() => {

    if (!floorplans || !searchParams && !searchRecentParams
      && storedLocation && storedLocationFloor) {

      return
    }

    const fp = floorplans.find(
      f => f.id === (searchParams?.floorplanId || searchRecentParams?.floorPlanID)
    )

    setStoredLocation(fp ? `${fp?.locationName}` : 'Floorplan')
    if (showFloorplan) {
      setStoredLocationFloor(fp ? `${fp?.name}` : '')
    }

  }, [floorplans, searchParams, searchRecentParams, showFloorplan])

  useEffect(() => {
    if (!cancellationParams) {
      return
    }

    const message = `Are you sure you want to cancel ${
      isOwnDesk(cancellationParams.bookingID)
        ? 'your'
        : `${getFirstNameFromBookingId(cancellationParams.bookingID)}'s`
    } desk booking for desk ${cancellationParams.deskName} in ${
      cancellationParams.floorPlanName
    } on ${format(cancellationParams.date, 'EEEE dd/MM/yyyy')}?`
    setShowConfirm({ message, open: true })
  }, [cancellationParams])

  useEffect(() => {
    bookingService
      .getLocations()
      .then(result => {
        dispatch(setDeskBookingLocationsState(result.locations))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />))
        })
      })
    bookingService
      .getFloorPlans()
      .then(result => {
        dispatch(setDeskBookingFloorplansState(result.floorPlans))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />))
        })
      })
    bookingService
      .getZonesAll()
      .then(result => {
        dispatch(setDeskBookingZonesState(result.zones))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />))
        })
      })
  }, [dispatch])

  const shouldFetchForwardBookings = useCallback(() => {
    const hasEmployee = employeeDetails && employeeDetails.employeeId !== 0
    const hasForwardCount = forwardDeskBookingsCount !== undefined
    return hasEmployee && !hasForwardCount
  }, [employeeDetails, forwardDeskBookingsCount])

  useEffect(() => {
    if (!shouldFetchForwardBookings()) {
      return
    }

    bookingService
      .getForwardDeskBookings(employeeDetails.employeeId)
      .then(result => {
        const theForwardBookings:number = result.forwardBookings ?? 0
        setForwadBookingsPreviousDisplayValue(theForwardBookings)
        dispatch(setForwardDeskBookingsCount(theForwardBookings))
      })
      .catch(_ => {
        dispatch(setForwardDeskBookingsCount(0))
      })
  }, [dispatch, employeeDetails, forwardDeskBookingsCount, shouldFetchForwardBookings])

  const shouldFetchMostUsedDesk = useCallback(() => {
    const hasEmployee = employeeDetails && employeeDetails.employeeId !== 0
    const hasMostUsedDesk = mostUsedDesk !== undefined
    return hasEmployee && !hasMostUsedDesk
  }, [employeeDetails, mostUsedDesk])

  useEffect(() => {
    if (!shouldFetchMostUsedDesk()) {
      return
    }

    bookingService
      .getMostUsedDesk(employeeDetails.employeeId)
      .then(result => {
        const desk = { ...result.response, deskName: result.response.deskName?.toUpperCase() }
        setPreviousMostUsedDeskName(desk.deskName)
        dispatch(setMostUsedDesk(desk))
      })
      .catch(_ => {
        dispatch(setMostUsedDesk({} as MostUsedDesk))
        setPreviousMostUsedDeskName(undefined)
      })
  }, [dispatch, employeeDetails.employeeId, shouldFetchMostUsedDesk])

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }

  const showMeBookingShared = async (bookingData: BookingSearchParams) => {

    const { locationId, floorplanId, featureId, date, from, to } = bookingData

    const selectedData = {
      selectedFromTo: {
        from,
        to,
      },
      searchType: BookingSearchType.SINGLE,
    }

    const singleQuery = true
    await handleSearch(
      dispatch,
      handleSearchError,
      singleQuery,
      zones,
      featuresForFloorPlan,
      employeeDetails,
      userInfo,
      selectedData,
      locationId,
      floorplanId,
      featureId || null,
      date || new Date()
    )
  }

  useEffect(() => {
    if (!showMeBookingID) {
      return
    }

    const controller = new AbortController()

    bookingService
      .getBookingById(controller, String(showMeBookingID))
      .then(result => {
        const { statusId, fromDate, featureId, floorPlanId, fromTime, toTime } = result.booking

        let bookingError = null

        if (statusId === BookingStatus.CANCELLED) {
          bookingError = BookingNotificationError.CANCELLED
        }

        if (dateToNumber(fromDate) < dateToNumber(new Date())) {
          bookingError = BookingNotificationError.EXPIRED
        }

        if (bookingError) {
          dispatch(setDeskBookingShowMeBookingID(undefined))

          return dispatch(showErrorMessage(<BookingErrorMessage name={bookingError} />))
        }

        const floorplan = floorplans.find(f => f.id === floorPlanId)

        if (!floorplan || !featureId) {
          return
        }

        const bookingDate = new Date(fromDate)

        const notificationBookingSearchParams = {
          locationId: floorplan?.locationId,
          floorplanId: floorPlanId,
          date: bookingDate,
          from: fromTime,
          to: toTime,
          autoSearch: true,
          featureId,
        }

        showMeBookingShared(notificationBookingSearchParams)

        batch(() => {
          dispatch(setDeskBookingSearchResults([]))
          dispatch(setDeskBookingSearchParams(undefined))
          dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
          dispatch(setDeskBookingFloorplanManuallySelected(true))
        })

        batch(() => {
          dispatch(setDeskBookingShowGridView(false))
          dispatch(setDeskBookingShowFloorplan(true))

          showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })

          dispatch(setDeskBookingLoading(true))

          dispatch(setDeskBookingSearchParams(notificationBookingSearchParams))

          dispatch(setDeskBookingLoading(true))

          dispatch(setBookingFloorplanViewingDate(bookingDate))
        })
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />))
        })
        dispatch(setDeskBookingLoading(false))
        dispatch(setDeskBookingShowMeBookingID(undefined))
      })
  }, [dispatch, showMeBookingID])

  const isBlockBooking = wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE

  const blockBookingsDateSort = (a: BookingBlockBooking, b: BookingBlockBooking) =>
    new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime()

  const handleBlockBooking = async () => {
    if (!selectedFeature || !searchParams) {
      return
    }

    const body: BookingBlockBookingPostBody = {
      employeeId:
        searchParams.employeeID && Number(searchParams.employeeID) > 0
          ? searchParams.employeeID
          : employeeDetails.employeeId,
      employeeName: searchParams.employeeName || userInfo?.name || '',
      bookings: finalSelection
        .filter(f => f.active)
        .map(m => ({
          floorPlanId: selectedFeature?.floorPlanId,
          featureId: m?.alternativeFeatureId || selectedFeature.id,
          status: 'Accepted',
          fromDate: format(m.date, 'yyyy-MM-dd'),
          toDate: format(m.date, 'yyyy-MM-dd'),
          fromTime: '00:00:00',
          toTime: '23:00:00',
        })).sort(blockBookingsDateSort),
      deskDetails: {
        deskName: getDeskNameWithRow(selectedFeature),
        location: locations.find(f => f.id === selectedFeature.locationId)?.name || '',
        floorPlanName: `${storedLocation} - ${storedLocationFloor}`,
      },
    }

    return bookingService
      .createBlockBooking(body)
      .then(result => {
        const blockBookings = body.bookings
        const desksBooked = blockBookings.length
        const s = desksBooked > 1 ? 's' : ''

        const {
          fromDate,
          fromTime,
          toTime,
        } = blockBookings[0]

        const startDate =
          new Date(fromDate) || bookingRange[BookingDateRange.FROM] || searchParams.date
        const endDate =
          new Date(blockBookings[desksBooked - 1].toDate) ||
          bookingRange[BookingDateRange.TO] ||
          searchParams.dateTo

        if (startDate && endDate && fromTime && toTime) {
          setBookingSearchRecentParams({
            fromDateTime: formatDatTimeWithTimeZoneStr(subMinutes(startDate, 1)),
            date: format(startDate, 'yyyy-MM-dd'),
            floorPlanID: selectedFeature?.floorPlanId,
            from: fromTime,
            to: toTime,
          })

          dispatch(
            showSuccessMessage(
              `You have successfully block-booked ${desksBooked} desk${s} between ${
                format(startDate, 'EEE, do MMM')} and ${format(endDate, 'EEE, do MMM')}`
            )
          )
          refreshForwardBookingsCount()
          refreshMostUsedDesk()
        }

        dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_1_SEARCH))

        resetViewToDeskBookingsDashboard()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />))
        })
      })
  }

  useEffect(() => {

    if (wizardCurrentStep !== BookingWizardSteps.STEP_5_SUBMIT) {
      return
    }

    handleBlockBooking()

  }, [wizardCurrentStep, finalSelection])

  const getBlockBookingResolutionText = () => {
    if (wizardCurrentStep !== BookingWizardSteps.STEP_4_ALTERNATIVES) {
      return ''
    }
    const resolving = finalSelection.find(
      f => f.conflicts.length > 0 && f.active && !f.alternativeFeatureId
    )
    if (!resolving || !resolving?.date) {
      return ''
    }
    return `Resolving: ${format(resolving?.date, 'EEE, do MMM')}`
  }

  const getForwardBookingsLabel = useCallback((): string => {
    if (forwardDeskBookingsCount === undefined) {
      return forwardBookingsPreviousDisplayValue?.toFixed(0) ?? ''
    }
    return forwardDeskBookingsCount.toFixed(0)
  }, [forwardBookingsPreviousDisplayValue, forwardDeskBookingsCount])

  const getMostUsedDeskName = useCallback(() => {
    if (!mostUsedDesk || !mostUsedDesk.deskName) {
      return previousMostUsedDeskName ?? ''
    }
    return mostUsedDesk.deskName
  }, [mostUsedDesk, previousMostUsedDeskName])

  const getTodaysBooking = useMemo(() => todaysBooking, [todaysBooking]) // useCallback(() => todaysBooking, [todaysBooking])

  if (!locations || !floorplans || !zones) {
    return null
  }

  const noConflictsOrResultsActive = () =>
    !finalSelection.some(
      s =>
        s.conflicts[0] &&
        s.active &&
        !s.alternativeFeatureId &&
        s.conflicts.filter(d => d.featureId === selectedFeature?.id).length > 0
    )

  const getWizardStepLabels = () => {
    if (
      (wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK ||
        wizardCurrentStep === BookingWizardSteps.STEP_3_SUMMARY) &&
      !selectedFeature?.hasBookings || wizardCurrentStep === BookingWizardSteps.STEP_3_UNAVAILABLE || 
      noConflictsOrResultsActive()
    ) {
      return wizardSteps.filter(f => f.index !== BookingWizardStepLabels.ALTERNATIVES)
    }
    return wizardSteps
  }

  const viewTitle = showGridView ? 'My Bookings' : storedLocation
  const viewSubTitle = showFloorplan && storedLocationFloor || undefined

  const isDashboardGridview = () => !showFilter && showGridView
  const isFilterFloorplan = () => !showSearch && showFilter
  const isSearchDesk = () =>  showSearch && (!showFilter || isBlockBooking || showBlockBookingWizard)
  const isDeskSearchJourney = () => isSearchDesk() && showFloorplan

  const showNavBarDashGridFilterFloorplan = () => showNavBar && (
    isDashboardGridview() || isFilterFloorplan()
  )
  const showMiniNavNewDeskSearch = () => !isBlockBooking && isDeskSearchJourney()

  const mobileToggleSearchDashboard = (state:boolean) => setMobileSearch(state)

  return (
    <>
      <Modal
        title="Please confirm"
        message={showConfirm.message}
        type="question"
        buttonLabel="Confirm"
        open={showConfirm.open}
        onClose={() => setShowConfirm({ message: '', open: false })}
        onClick={() => {
          handleCancellation(isMobileLayout, cancellationParams, employeeDetails, gridViewingDate, dispatch, err =>
            dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
          )
          setShowConfirm({ message: '', open: false })
        }}
      />
      <PageHeader title="Desk Booking" />
      {isMobileLayout && (
        <Grid item xs={8}>
          <Card
            style={{
              backgroundColor: '#fff',
              boxShadow: 'none',
              padding: isMobileLayout ? '0px' : '20px 32px 32px 32px',
              marginTop: '-32px',
              position: 'relative',
              borderRadius: '0px'
            }}
          >
            <Box ref={containerRef} sx={{ position: "relative", overflow: "hidden" }}>
              {!showFloorplan &&
                <Box style={{padding: isMobileLayout ? '20px 32px 32px 32px' : '0px'}}>
                  {mobileSearch && mobileRedesignDrop3 &&
                    <Slide
                      direction="left"
                      in={mobileSearch}
                      container={containerRef.current}
                      mountOnEnter
                      unmountOnExit
                      easing={{
                        enter: "cubic-bezier(0, 1.5, .8, 1)",
                        exit: "linear"
                      }}
                      timeout={{
                        appear: 1000,
                        enter: 900,
                        exit: 300
                      }}
                    >
                      <Grid container id="mobileSearch" display="flex" justifyContent="flex-end">
                        <BookingSearch 
                          dashboardButtonOnClick={() => {
                            mobileToggleSearchDashboard(false)
                            // dispatch(setDeskBookingInfinityBookingsResults([]))
                            // getLatestEmployeeDashboardBookings(isMobileLayout)
                          }}
                        />
                      </Grid>
                    </Slide>
                  }
                  {!mobileSearch &&
                    <Slide
                      direction="right"
                      appear={!!mobileSearch}
                      in={!mobileSearch}
                      container={containerRef.current}
                      mountOnEnter
                      easing={{
                        enter: "cubic-bezier(0, 1.5, .8, 1)",
                        exit: "linear"
                      }}
                      timeout={{
                        appear: 1000,
                        enter: 900,
                        exit: 300
                      }}
                    >
                      <Grid xs={12} sm={12} container id="mobileBookings" display="flex" justifyContent="flex-end">
                        <Grid item xs display="flex" alignItems="center">
                          <Box flexGrow={1}>
                            <CardTitle title={viewTitle} />
                          </Box>
                        </Grid>
                        {mobileRedesignDrop3 &&
                          <Grid item width="100px" display="flex" alignItems="center" pr={2}>
                            <Box flexGrow={1}>
                              <ButtonSmall
                                sx={{padding: '2px !important', margin: '0px', top: '6px', marginBottom: '14px', minWidth: '100px', fontWeight: 600, fontSize: '11px'}}
                                label="New Booking"
                                onClick={() => mobileToggleSearchDashboard(true)}
                              />
                            </Box>
                          </Grid>
                        }
                        <BookingScrollView
                          onCancel={({
                            bookingID: bookingIDLocal,
                            deskName,
                            floorPlanName,
                            location,
                            byManager,
                            date,
                            onCallBack,
                          }: BookingCancellationProps) => {
                            setCancellationParams({
                              bookingID: bookingIDLocal,
                              deskName,
                              floorPlanName,
                              location,
                              byManager,
                              date,
                              onCallBack,
                            })
                          }}
                          onDataLoaded={booking => {
                            onBookingGridViewDataLoaded(booking)
                          }}
                          onBookMoreDesks={() => mobileToggleSearchDashboard(true)}
                          onShowMeShared={bookingData => showMeBookingShared(bookingData)}
                        />
                        <div
                          style={{
                            color: '#000',
                            height: '50px',
                            width: '100%',
                            zIndex: BOOKING_DASHBOARD_FEATURE_GRID_VIEW_BLEND,
                            position: 'fixed',
                            bottom: '0px',
                            left: 0,
                            background:
                              'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)',
                            pointerEvents: 'none',
                          }}
                        />
                      </Grid>
                    </Slide>
                  }
                </Box>
              }
              {showFloorplan &&
                <Grid
                  item
                  spacing={4}
                  pt={0}
                  pb={0}
                  pr={3}
                  pl={3}
                  xs={12}
                  height={(!isDeskSearchJourney() || isBlockBooking || showFilter) ? '625px' : '800px'}
                  display={showFloorplan ? 'unset' : 'none'}
                  sx={{marginTop: isDeskSearchJourney() && (!isBlockBooking && !showFilter) ? '-90px' : 'unset'}}
                >
                  <BookingFloorplanView
                    onCancel={({
                      bookingID: bookingIDLocal,
                      deskName,
                      floorPlanName,
                      location,
                      byManager,
                      date,
                      onCallBack,
                    }: BookingCancellationProps) => {
                      setCancellationParams({
                        bookingID: bookingIDLocal,
                        deskName,
                        floorPlanName,
                        location,
                        byManager,
                        date,
                        onCallBack,
                      })
                    }}
                  />
                </Grid>
              }
            </Box>
          </Card>
        </Grid>
      )}
      {!isMobileLayout && (
        <Card padding="0" style={{ position: 'relative', height: '710px' }}>
          <Grid container>
            <Grid
              item
              xs={!showSearch && showFilter ? 2.3 : 2.9}
              p={4}
              pt={2.7}
              borderRight="1px solid #0000001F"
              sx={{ position: 'relative', alignItems: 'flex-start1', transition: 'all .5s ease-in-out' }}
            >
              {
                isSearchDesk() &&
                  <BookingSearch />
              }
              {
                isFilterFloorplan() &&
                  <BookingFloorplanFilter />
              }
            </Grid>
            <Grid item xs>
              <Grid container id="navbarcont">
                {showNavBarDashGridFilterFloorplan() && (
                  <Grid item id="navbaritem" xs={12} pt={2.5} pb={1.5} pr={4} pl={4}>
                    <NavBar
                      title={viewTitle}
                      subTitle={viewSubTitle}
                      onNav={() => {
                        dispatch(setDeskBookingLoading(true))
                      }}
                    />
                  </Grid>
                )}
                {showMiniNavNewDeskSearch() && (
                  <Grid
                    item
                    id="navbaritem"
                    xs={12}
                    pt={2.5}
                    pb={0}
                    pr={4}
                    pl={4}
                    width="80px"
                    display="flex"
                    alignItems="left"
                    sx={{backgroundColor: 'white !important', zIndex: BOOKING_BLOCK_FEATURE_MIN_NAV_OVERLAY}}
                  >
                    <Box flexGrow={1}>
                      <CardTitleSub
                        title={viewTitle}
                        subTitle={viewSubTitle}
                      />
                      <ButtonSmall
                        sx={{padding: '2px !important', marginTop: '22px', minWidth: '100px', fontWeight: 600, fontSize: '11px'}}
                        label="&lt; Back"
                        onClick={() => batch(() => {
                          dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
                          resetViewToDeskBookingsDashboard()
                        })}
                      />
                    </Box>
                  </Grid>
                )}

                {showBlockBookingWizard && (
                  <Grid
                    item
                    xs={12}
                    pt={2.5}
                    pb={0}
                    pr={4}
                    pl={4}
                    p="32px 32px"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: 'white !important', zIndex: BOOKING_BLOCK_FEATURE_MIN_NAV_OVERLAY}}
                  >
                    <WizardSteps
                      steps={getWizardStepLabels()}
                      value={Number(wizardCurrentStep >= 2 ? wizardCurrentStep - 1 : wizardCurrentStep)}
                      primaryStepColour="#747474"
                      onChange={_idx => undefined}
                    />
                    <Paragraph weight="bold" padding="0 32px">
                      {getBlockBookingResolutionText()}
                    </Paragraph>
                  </Grid>
                )}
                <Grid container spacing={4} mt={-2.8} mr={4} mb={0} ml={0}>
                  {/* Col 1 */}
                  <Grid item xs={5} display={showGridView ? 'unset' : 'none'}>
                    <Stack direction="column" justifyContent="space-between" height="100%">
                      <Box>
                        <BookingTodaysDesk
                          todaysBooking={getTodaysBooking}
                          onCheckIn={(id, deskLabel) =>
                            handleCheckIn(id, employeeDetails.employeeId, deskLabel, dispatch, err =>
                              dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
                            )
                          }
                        />
                      </Box>
                      <Box>
                        <BookingShowMe
                          todaysBookingData={getTodaysBooking}
                          onShowMeShared={bookingData => showMeBookingShared(bookingData)}
                        />
                      </Box>
                      <Box>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <BookingGeneralDesksInfo
                              backgroundColor="#02ACEC24"
                              icon={<DeskIcon fontSize="large" style={{ color: '#2C2965' }} />}
                              header="Most used desk"
                              label={getMostUsedDeskName()}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <BookingGeneralDesksInfo
                              backgroundColor="#FFB02325"
                              icon={
                                <TodayIcon
                                  style={{ color: '#2C2965', fontSize: '28px', marginTop: '4px' }}
                                />
                              }
                              header="Forward Bookings"
                              label={getForwardBookingsLabel()}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* End Col 1 */}
                  {/* Col 2 */}
                  <Grid item xs={7} display={showGridView ? 'unset' : 'none'}>
                    <Card
                      style={{
                        backgroundColor: '#f4f4f4',
                        boxShadow: 'none',
                        padding: '32px 16px 32px 32px',
                        position: 'relative',
                      }}
                    >
                      <BookingGridViewToggle
                        title={
                          isSameMonth(gridViewingDate, new Date())
                            ? 'This Month'
                            : MONTHS[gridViewingDate.getMonth()]
                        }
                      />
                      <BookingGridView
                        onCancel={({
                          bookingID: bookingIDLocal,
                          deskName,
                          floorPlanName,
                          location,
                          byManager,
                          date,
                          onCallBack,
                        }: BookingCancellationProps) => {
                          setCancellationParams({
                            bookingID: bookingIDLocal,
                            deskName,
                            floorPlanName,
                            location,
                            byManager,
                            date,
                            onCallBack,
                          })
                        }}
                        onDataLoaded={booking => {
                          onBookingGridViewDataLoaded(booking)
                        }}
                        onShowMeShared={bookingData => showMeBookingShared(bookingData)}
                      />
                      <div
                        style={{
                          color: '#000',
                          height: '50px',
                          width: '100%',
                          zIndex: BOOKING_DASHBOARD_FEATURE_GRID_VIEW_BLEND,
                          position: 'absolute',
                          bottom: '32px',
                          left: 0,
                          background:
                            'linear-gradient(180deg, rgba(244,244,244,0) 0%, rgba(244,244,244,1) 100%)',
                          pointerEvents: 'none',
                        }}
                      />
                    </Card>
                  </Grid>
                  {/* End Col 2 */}
                </Grid>
                {showFloorplan && (
                  <Grid
                    item
                    spacing={4}
                    pt={0}
                    pb={0}
                    pr={3}
                    pl={3}
                    xs={12}
                    height={(!isDeskSearchJourney() || isBlockBooking || showFilter) ? '625px' : '800px'}
                    display={showFloorplan ? 'unset' : 'none'}
                    sx={{marginTop: isDeskSearchJourney() && (!isBlockBooking && !showFilter) ? '-90px' : 'unset'}}
                  >
                    <BookingFloorplanView
                      onCancel={({
                        bookingID: bookingIDLocal,
                        deskName,
                        floorPlanName,
                        location,
                        byManager,
                        date,
                        onCallBack,
                      }: BookingCancellationProps) => {
                        setCancellationParams({
                          bookingID: bookingIDLocal,
                          deskName,
                          floorPlanName,
                          location,
                          byManager,
                          date,
                          onCallBack,
                        })
                      }}
                    />
                  </Grid>
                )}
                {showBlockBookingSummary && showBlockBookingWizard && (
                  <Grid
                    item
                    xs={12}
                    height={(isBlockBooking || showFilter) || !showBlockBookingWizard ? '625px' : '800px'}
                    sx={{marginTop: isDeskSearchJourney() && (!isBlockBooking && !showFilter) ? '-90px' : 'unset'}}
                  >
                    <BookingBlockSummary />
                  </Grid>
                )}
                {Number(wizardCurrentStep) >= BookingWizardSteps.STEP_2_SELECT_DESK && showBlockBookingWizard && (
                  <BookingBlockStatus
                    showLabels={
                      wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK ||
                      wizardCurrentStep === BookingWizardSteps.STEP_4_ALTERNATIVES
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  )
}
